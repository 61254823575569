<template>
	<section>
		<section class="search-container">
			<a-form layout="inline" class="m-t-14">
				<a-form-item label="预警等级：">
					<a-input
						placeholder="请输入"
						v-model="formSearch.gradeName"
						allowClear
					/>
				</a-form-item>
				<a-form-item label="自定义名称：">
					<a-input
						placeholder="请输入"
						v-model="formSearch.gradeValue"
						allowClear
					/>
				</a-form-item>
				<a-form-item>
					<a-button type="primary" class="m-r-8" @click="btnQuery"
						><a-icon type="search" />查询</a-button
					>
					<a-button class="m-r-8" @click="btnReset"
						><a-icon type="reload" />重置</a-button
					>
					<a-button type="primary" class="m-r-8" @click="editAlarmGrade()"
						>添加等级</a-button
					>
				</a-form-item>
			</a-form>
		</section>
		<section class="list-table-container m-t-18">
			<a-table
				size="small"
				:columns="columns"
				:data-source="tableData"
				:pagination="{
					total,
					hideOnSinglePage: true,
					current: searchData.current
				}"
				@change="pageNumChange"
			>
				<a slot="action" slot-scope="text, record">
					<template v-if="record.allowDelete">
						<a
							href="javascript: void(0)"
							class="m-r-10"
							@click="editAlarmGrade(record)"
							>编辑</a
						>
						<a-popconfirm
							title="确定删除该预警等级?"
							@confirm="deleteItem(record.id)"
							>删除</a-popconfirm
						>
					</template>
					<template v-else>
						<a href="javascript: void(0)" @click="editAlarmGrade(record)"
							>编辑</a
						>
					</template>
				</a>
			</a-table>
		</section>
		<a-drawer
			class="drawer_module"
			:title="dataSelected ? '编辑预警等级' : '添加预警等级'"
			placement="right"
			:width="400"
			:maskClosable="false"
			:visible="editVisible"
			@close="btnCancel"
		>
			<a-form
				:form="form"
				:colon="false"
				:labelCol="{ span: 6 }"
				:wrapperCol="{ span: 18 }"
			>
				<a-form-item label="预警等级">
					<a-input
						:disabled="true"
						v-decorator="[
							'gradeName',
							{ rules: [{ required: true, message: '预警等级为必填项' }] }
						]"
					/>
				</a-form-item>
				<a-form-item label="自定义名称">
					<a-input
						v-decorator="[
							'gradeValue',
							{ rules: [{ required: true, message: '自定义名称为必填项' }] }
						]"
					/>
				</a-form-item>
				<a-form-item :wrapper-col="{ span: 18, offset: 6 }">
					<a-button type="primary" @click="btnConfirm">保存</a-button>
					<a-button style="margin-left: 10px" @click="btnCancel">关闭</a-button>
				</a-form-item>
			</a-form>
		</a-drawer>
	</section>
</template>

<script>
	export default {
		name: 'AlarmLevel',
		data() {
			return {
				form: this.$form.createForm(this),
				formSearch: {
					gradeName: '',
					gradeValue: ''
				},
				searchData: {
					gradeName: '',
					gradeValue: '',
					current: 1,
					size: 10
				},
				columns: [
					{
						title: '序号',
						dataIndex: 'key',
						align: 'center'
					},
					{
						title: '预警等级',
						dataIndex: 'gradeName',
						align: 'center'
					},
					{
						title: '自定义名称',
						dataIndex: 'gradeValue',
						align: 'center'
					},
					{
						title: '操作',
						dataIndex: 'action',
						align: 'center',
						scopedSlots: { customRender: 'action' }
					}
				],
				tableData: [],
				total: 0,
				editVisible: false,
				dataSelected: null
			}
		},
		mounted() {
			this.queryAlarmGrade()
		},
		watch: {
			editVisible(nVal) {
				if (nVal && this.dataSelected) {
					this.$nextTick(() => {
						this.form.setFieldsValue({
							gradeName: this.dataSelected.gradeName,
							gradeValue: this.dataSelected.gradeValue
						})
					})
				} else {
					this.$nextTick(() => {
						this.form.setFieldsValue({
							gradeName: this.total + 1 + '级预警',
							gradeValue: ''
						})
					})
				}
			}
		},
		methods: {
			queryAlarmGrade() {
				this.$api.queryAlarmGrade(this.searchData).then((res) => {
					if (res.code === 200) {
						this.tableData = res.data.records
							? res.data.records.map((item, index) => {
									item.key =
										(this.searchData.current - 1) * this.searchData.size +
										index +
										1
									if (item.key !== res.data.total) {
										item.allowDelete = false
									} else {
										item.allowDelete = true
									}
									return item
							  })
							: []
						this.total = res.data.total
					}
				})
			},
			pageNumChange(pagination) {
				this.searchData.current = pagination.current
				this.queryAlarmGrade()
			},
			btnQuery() {
				this.searchData.gradeName = this.formSearch.gradeName
				this.searchData.gradeValue = this.formSearch.gradeValue
				this.searchData.current = 1
				this.queryAlarmGrade()
			},
			btnReset() {
				this.searchData.gradeName = this.formSearch.gradeName = ''
				this.searchData.gradeValue = this.formSearch.gradeValue = ''
				this.searchData.current = 1
				this.queryAlarmGrade()
			},
			editAlarmGrade(record) {
				this.dataSelected = record
				this.editVisible = true
			},
			btnConfirm() {
				this.form.validateFields((errors, values) => {
					if (!errors) {
						if (this.dataSelected) {
							this.$api
								.updateAlarmGrade({
									id: this.dataSelected.id,
									gradeName: values.gradeName,
									gradeValue: values.gradeValue
								})
								.then((res) => {
									if (res.code === 200) {
										this.editVisible = false
										this.queryAlarmGrade()
									}
								})
								.catch((err) => {
									console.log(err)
								})
						} else {
							this.$api
								.insertAlarmGrade({
									gradeName: values.gradeName,
									gradeValue: values.gradeValue
								})
								.then((res) => {
									if (res.code === 200) {
										this.editVisible = false
										this.queryAlarmGrade()
									}
								})
								.catch((err) => {
									console.log(err)
								})
						}
					} else {
						return false
					}
				})
			},
			btnCancel() {
				this.form.resetFields()
				this.editVisible = false
			},
			deleteItem(id) {
				this.$api
					.deleteAlarmGrade(id)
					.then((res) => {
						if (res.code === 200) {
							if (this.tableData.length === 1 && this.searchData.current > 1) {
								this.searchData.current -= 1
							}
							this.queryAlarmGrade()
						}
					})
					.catch((err) => {
						console.log(err)
					})
			}
		}
	}
</script>
